<template>
    <div class="w-full">
        <div :class="[{'py-4': !type, 'py-8': type}]" v-if="!titleOff">
            <div class="text-[#292B33]"
                 :class="[{'px-4 text-xl text-center ': type === 'popup'}, {'text-base md:text-xl text-left': type === 'inline'}]">
                {{ title }}
            </div>

            <div :class="[{'text-center x-4':type==='popup'}]" class="py-2 text-base" v-if="subtitle.length">{{ subtitle }}</div>
        </div>

        <div class="grid grid-cols-2 gap-2">
            <a
                    href="https://t.me/fominclinic_kids_bot"
                    target="_blank"
                    class="flex items-center mb-4 border rounded-full border-[#EFEDF2] px-3 py-1 text-lg text-[#2D2A34] hover:text-black h-12"
            >
                <span class="grow text-center">Telegram</span>

                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.9698 9.45694C21.9698 9.45694 23.4498 8.85094 23.326 10.3225C23.2852 10.9285 22.9153 13.0497 22.6273 15.3437L21.6406 22.1397C21.6406 22.1397 21.5583 23.1353 20.8181 23.3085C20.0783 23.4813 18.9682 22.7025 18.7625 22.5293C18.5979 22.3993 15.679 20.4513 14.6512 19.4993C14.3632 19.2393 14.0341 18.7201 14.6924 18.1141L19.0093 13.7853C19.5027 13.2653 19.996 12.0533 17.9404 13.5253L12.1841 17.6373C12.1841 17.6373 11.5262 18.0705 10.2931 17.6809L7.62028 16.8149C7.62028 16.8149 6.63361 16.1657 8.31934 15.5165C12.431 13.4821 17.4882 11.4045 21.9694 9.45654"
                          fill="#252A33"/>
                </svg>
            </a>
            <a
                    :href="link"
                    target="_blank"
                    class="flex items-center mb-4 border rounded-full border-[#EFEDF2] px-3 py-1 text-lg text-[#2D2A34] hover:text-black"
            >
                <span class="grow text-center">WhatsApp</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.04 2C6.57999 2 2.12999 6.45 2.12999 11.91C2.12999 13.66 2.58999 15.36 3.44999 16.86L2.04999 22L7.29999 20.62C8.74999 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C18.1331 3.98411 17.041 3.24997 15.8375 2.75036C14.634 2.25076 13.3431 1.99568 12.04 2ZM12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C18.6354 6.85552 19.2422 7.76451 19.6558 8.76492C20.0693 9.76533 20.2814 10.8375 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.10999 19.76 7.84999 19L7.54999 18.83L4.42999 19.65L5.25999 16.61L5.05999 16.29C4.23453 14.9784 3.79766 13.4597 3.79999 11.91C3.80999 7.37 7.49999 3.67 12.05 3.67ZM8.52999 7.33C8.36999 7.33 8.09999 7.39 7.86999 7.64C7.64999 7.89 6.99999 8.5 6.99999 9.71C6.99999 10.93 7.88999 12.1 7.99999 12.27C8.13999 12.44 9.75999 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.76999 11.64 9.61999 11.39C9.49999 11.15 9.60999 11 9.72999 10.89C9.83999 10.78 9.99999 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.76999 8.26 9.55999 7.77C9.35999 7.29 9.15999 7.35 8.99999 7.34C8.85999 7.34 8.69999 7.33 8.52999 7.33Z"
                          fill="#252A33"/>
                </svg>

            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'subtitle', 'link', 'type', 'titleOff'],
    computed: {
        title() {
            return this.title ? this.title : 'Для записи напишите нам в любой мессенджер:';
        },
        subtitle() {
            return this.subtitle ? this.subtitle : '';
        },
        link() {
            return this.link ? this.link : 'https://wa.me/79161006469';
        },
    }
};
</script>
